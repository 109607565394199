.modal-open .modal {
    overflow-y: scroll;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-max {
    width: 95% !important;
    max-width: none !important;
}

.modal-full {
    width: 100%;
    max-width: none !important;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: 0 !important;
}

.modal-full .modal-content {
    border: none;
    border-radius: 0;
    min-height: 100%;
}
